//
//

.disappearing-button-container-page-top-right {
  position: absolute;
  right: 300px;
  top: 85px;
  text-align: 'center';
  z-index: 10;
  @media (max-width: 850px) {
    display: none;
  }
}

.appearing-button-container-page-bottom-right {
  position: absolute;
  bottom: 80px;
  right: 50px;
  text-align: 'center';
  z-index: 10;
  @media (min-width: 850px) {
    display: none;
  }
}
.invalid{
  border-color:1px solid red;
}
.details_card{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top: -35px;
    margin-left: 1rem;
    // margin-left: 3rem;
}
.details_card_child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-left: 1rem;
    margin-right: 1rem;
    // padding: 0px 555px 0px 42px
    // padding: 0px 45px 0px 50px;

    transform: scaleY(1);




  }

.details_card_image{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .details_card__Togglebutton{
    display: flex;
    justify-content: flex-end;
    
  }

.detail__card__rightLogos{
width: 15%;
height: 200px;
min-width: 200px;
}

 
  .detail_item {
    width: 100%;
    height: 550px;
    padding: 10px;
    background-color: #fff;
    transition: height 500ms ease;
    overflow: hidden;
}

.detail_none {
    height: 230px;
    margin-bottom: 20px;

    
}
.gtcc_detail_none {
  height: 100%;
  margin-bottom: 20px;

  
}
.detail_showing {
    height: 560px;
    display: block !important;
    padding: 10px;
}

.gtcc__detail_showing{
  height: 420px;
  display: block !important;
  padding: 10px;
}

.card__tiles{

border-radius: 0.6em;
box-shadow: 3px 3px 11px 0px rgba(196,196,196,1);
transition: all ease 200ms;
animation: fadeInUp 800ms;
 
}

.card__tiles:hover {
  transform: scale(1.02);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card__tiles__image{
  border-radius: 0.6em;
  cursor: pointer;
  transition: all ease 200ms; 
  }

  .card__tiles__image:hover {
    transform: scale(1.04);
  }


.card__tile__imagegrid{
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr;
  grid-column-gap: 10px;

  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
  }

}


.entity__image__fade-in{
  animation: fadeIn 5s;
  transition:all 500ms ease;
}


@keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

.testing{
display: block !important;
transform: scaleY(1);

}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}


.rmsc.multiselect__style{

  z-index: 9999 !important;
  
}
.terms_p{
  font-size: "13px";
  font-weight: "bold";
  text-decoration: underline;
}



.gatePage__circle{
  border: solid 1px;
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  line-height: 0;
  position: relative;

}

.datePickerTitle{
  @media (max-width: 570px) {
    display: none;
  }
}

.sensor_report_calender_container{
  display: flex;
  margin-bottom: -55px;
  width: 100%;
  @media (max-width: 820px) {
   flex-direction: column;
}
}

.position_center_class{
  position: absolute;
  top:50%;
  left: 50%;
transform: translate(-50%,-50%);
}



.release-note-container{


  width: 100%;
  border-radius: 5px;
  border: 1px solid #E1E5E9;
  padding: 5px;
  overflow:hidden ;
  &:hover {
  overflow-y: auto;
  
  }
  }
  
  .release-note-container::-webkit-scrollbar {
    width: 6px;
  
  }
  .release-note-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(223, 223, 223); 
    border-radius: 10px;
  }
   
  /* Handle */
  .release-note-container::-webkit-scrollbar-thumb {
    background:#d8d8d8; 
    border-radius: 10px;
  }
  
  .release-note-card-action{
    position:absolute;
    right: 2px;
    display: none;
  }
 
  
  .release-note-card{
  width: 100%;
  min-height: 50px;
 
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  &:hover {
    background-color: #eae8e8;
    .release-note-card-action{
      display: block;
    }
    }
  
  
  }

  input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}


.position_center_class {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position_center_class_for_email {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-55%, -50%);
}

.msg_notificatins::-webkit-scrollbar {
  width: 7px;
}



.msg_notificatins {
  background-color: #fdfdfd;
  overflow: hidden;

  &:hover {
    overflow-y: auto;

  }
}



/* Track */
.msg_notificatins::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(223, 223, 223);
  border-radius: 10px;
}

/* Handle */
.msg_notificatins::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}


.notification-card {
  width: 100;
  user-select: none;
  min-height: 75px;
  padding: 5px;
  border-bottom: 1px solid #E0E0E0;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1)
  }

}


  

